import { getIn } from 'formik'
import { useEffect, useState } from 'react'
import ReactDragListView from 'react-drag-listview/lib/index.js'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, CardBody, Col, Label, Row } from 'reactstrap'
import { photosFields } from '../../common/forms/photos/fields'
import {
    deleteGroundPlan,
    fetchGroundPlans,
    proxyGroundPlanEdit,
    proxyGroundPlanEditClear,
    reorderGroundPlans,
    selectGroundPlans,
    selectGroundPlansFetching,
    selectGroundPlansProxyPhoto,
    selectGroundPlansProxyPhotoFetching,
    syncGroundPlan,
    updateGroundPlansPriority,
    uploadGroundPlans,
} from '../../store/groundplans'
import { proxyPhotoEdit, proxyPhotoEditClear, selectPhotosProxyPhoto } from '../../store/photos'
import { selectProject } from '../../store/projects'
import { selectProperty } from '../../store/properties'
import AppPermissionsSwitcher from '../AppPermissionsSwitcher/AppPermissionsSwitcher'
import DeleteModal from '../Common/DeleteModal'
import FormMainContainer from '../Common/FormMainContainer'
import FormSideContainer from '../Common/FormSideContainer'
import FormTabNavigation from '../Common/FormTabNavigation'
import Loader from '../Common/Loader'
import Dropzone from '../Dropzone/Dropzone'
import useLocaleOptions from '../Hooks/useLocaleOptions'
import PhotoCard from '../PhotoCard'
import ToastImageEditor from '../ToastImageEditor/ToastImageEditor'
import { API_URL } from '../../common/constants'

const GroundPlansFormTab = ({
    groundPlansModule,
    parentId,
    tabs = [],
    activeTab,
    handleTabChange,
    childTabs = [],
    activeChildTab,
    handleChildTabChange,
    validation,
}) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const localeOptions = useLocaleOptions()

    const { groundplans, fetching, entity, proxyPhoto, proxyPhotoFetching } = useSelector((state) => ({
        groundplans: selectGroundPlans(state),
        fetching: selectGroundPlansFetching(state),
        entity: groundPlansModule === 'project' ? selectProject(state) : selectProperty(state),
        proxyPhoto: selectGroundPlansProxyPhoto(state),
        proxyPhotoFetching: selectGroundPlansProxyPhotoFetching(state),
    }))

    const [fullscreenImg, setFullscreenImg] = useState(null)
    const [selectedGroundPlans, setSelectedGroundPlans] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteModalMulti, setDeleteModalMulti] = useState(false)
    const [invoice, setInvoice] = useState(null)
    const [groundPlansTabClicked, setGroundPlansTabClicked] = useState(false)

    useEffect(() => {
        if (activeTab === 'materials') {
            window.scrollTo(0, 0)
        }
    }, [activeTab])

    useEffect(() => {
        if (activeTab !== 'materials' || activeChildTab !== 'groundPlans') {
            dispatch(proxyGroundPlanEditClear())
            setFullscreenImg(null)
        }
    }, [activeTab, activeChildTab, dispatch])

    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            dispatch(
                reorderGroundPlans({
                    fromIndex,
                    toIndex,
                })
            )
        },
        nodeSelector: '.drag-node',
        handleSelector: '.mdi-menu',
        lineClassName: 'dmn-photo-drag',
    }

    const onClickDelete = () => {
        if (selectedGroundPlans.length) {
            setDeleteModalMulti(true)
            return
        }

        setInvoice(fullscreenImg)
        setDeleteModal(true)
        dispatch(proxyGroundPlanEditClear())
    }

    const handleDeleteInvoice = () => {
        if (invoice) {
            dispatch(deleteGroundPlan(invoice.id))
            setDeleteModal(false)
            setFullscreenImg(null)
            setSelectedGroundPlans([])
        }
    }

    const deleteMultiple = () => {
        for (const [i, id] of selectedGroundPlans.entries()) {
            dispatch(deleteGroundPlan({ id, entity }))
        }

        setSelectedGroundPlans([])
        dispatch(updateGroundPlansPriority(selectedGroundPlans))
    }

    useEffect(() => {
        const isGroundPlansClicked = activeTab === 'materials' && activeChildTab === 'groundPlans'

        if (isGroundPlansClicked) {
            setGroundPlansTabClicked(true)
        } else {
            setGroundPlansTabClicked(false)
        }
    }, [activeTab, activeChildTab])

    useEffect(() => {
        if (groundPlansTabClicked && !groundplans.length) {
            dispatch(
                fetchGroundPlans(
                    {
                        ...(parentId ? { parentId: parentId } : {}),
                        noLimit: 1,
                        order: { priority: 'asc' },
                        criteria: {
                            collection: 'ground_plan',
                            ...(groundPlansModule ? { module: groundPlansModule } : {}),
                        },
                    },
                    (count) => {
                        // console.log('count from meta', count)
                        // setPageCount(Math.floor(count / limit))
                    }
                )
            )
        }
    }, [dispatch, parentId, groundPlansModule, groundPlansTabClicked, groundplans.length])

    useEffect(() => {
        for (const [i, k] of groundplans.entries()) {
            const photoItem = getIn(validation.values.groundplans, `${k.id}`)

            if (!photoItem) {
                validation?.setFieldValue(`groundplans.${k.id}`, {
                    id: k.id,
                    [photosFields.FILE_DESCRIPTION]: k.fileDescriptionTranslations,
                    [photosFields.PRIORITY]: k.priority,
                })
            }
        }
    }, [groundplans])

    const handleGroundplansUpload = (files) => {
        dispatch(
            uploadGroundPlans({
                files,
                entity,
                photosModule: groundPlansModule,
            })
        )
    }

    const handleFullscreenImg = (img) => {
        setFullscreenImg(img)

        handleProxyPhotoEdit(img)
        deselectPhotos()
    }

    const handleProxyPhotoEdit = (img) => {
        if (img) {
            dispatch(proxyGroundPlanEdit(img.id))
        } else {
            dispatch(proxyGroundPlanEditClear())
        }
    }

    const deselectPhotos = () => {
        const inputs = document.querySelectorAll('.materialsPhotoCheckbox:checked')
        inputs.forEach((input) => (input.checked = false))
    }

    const handlePhotosCheckboxes = () => {
        let inputs
        let values = []

        switch (activeChildTab) {
            case 'groundPlans':
                inputs = document.querySelectorAll('.materialsGroundPlanCheckbox:checked')

                inputs.forEach((element) => {
                    values.push(element.value)
                })
                setSelectedGroundPlans(values)
                dispatch(proxyGroundPlanEditClear())
                break
            default:
                break
        }
    }

    const renderDownloadUrl = () => {
        let dlUrl = `${API_URL}v1/file/download/`
        let selectedIds = selectedGroundPlans.map((x) => parseInt(x))

        if (fullscreenImg && fullscreenImg.id) {
            selectedIds.push(fullscreenImg.id)
        }
        let uniqueIds = [...new Set(selectedIds)]

        dlUrl = dlUrl + uniqueIds.join(',')
        return dlUrl
    }

    const checkedAll = () => {
        let ele = document.querySelectorAll('.materialsGroundPlanCheckbox')

        if (!selectedGroundPlans.length) {
            let values = []
            ele.forEach((ele) => {
                ele.checked = true
                values.push(ele.value)
            })
            setSelectedGroundPlans(values)
        } else {
            setSelectedGroundPlans([])
            ele.forEach((ele) => {
                ele.checked = false
            })
        }
    }

    return (
        <Row>
            <FormSideContainer>{activeTab === 'materials' && <Dropzone callback={handleGroundplansUpload} tab={activeChildTab} />}</FormSideContainer>

            <FormMainContainer tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange}>
                <div className="d-flex justify-content-between align-items-center">
                    {childTabs.length ? (
                        <FormTabNavigation
                            handleTabChange={handleChildTabChange}
                            tabs={childTabs}
                            activeTab={activeChildTab}
                            customClass="real-estate-form-subtabs mb-4"
                        />
                    ) : (
                        ''
                    )}

                    <div className="hstack gap-2 d-print-none">
                        {proxyPhoto && (
                            <Button
                                onClick={(e) => {
                                    e.preventDefault()
                                    handleFullscreenImg(null)
                                }}
                                color="dark"
                                className="btn-icon"
                                style={{ zIndex: 2 }}
                            >
                                <i className="mdi mdi-close" />{' '}
                            </Button>
                        )}
                        <Button
                            color="warning"
                            className="btn-label"
                            disabled={!!proxyPhoto}
                            onClick={(e) => {
                                e.preventDefault()
                                checkedAll()
                            }}
                        >
                            <i className="mdi mdi-checkbox-multiple-outline label-icon align-middle fs-16 me-2"></i>{' '}
                            {selectedGroundPlans.length ? t('button.deselect') : t('button.select')}
                        </Button>
                        {selectedGroundPlans.length ? (
                            <>
                                <Button
                                    color="danger"
                                    className="btn-label"
                                    disabled={fullscreenImg === null && selectedGroundPlans.length === 0}
                                    onClick={() => {
                                        onClickDelete()
                                    }}
                                >
                                    <i className="mdi mdi-trash-can-outline label-icon align-middle fs-16 me-2"></i> {t('button.delete')}
                                </Button>
                                <Button
                                    tag="a"
                                    href={renderDownloadUrl()}
                                    color="edit"
                                    className="btn-label"
                                    download={true}
                                    disabled={fullscreenImg === null && !selectedGroundPlans.length}
                                >
                                    <i className="mdi mdi-download label-icon align-middle fs-16 me-2"></i>
                                    {t('button.download')}
                                </Button>
                            </>
                        ) : null}
                    </div>
                </div>

                {(proxyPhotoFetching || proxyPhoto) && (
                    <>
                        <Row className="mb-5 position-relative">
                            <>
                                {proxyPhotoFetching && (
                                    <div className="large-spinner-container">
                                        <Loader />
                                    </div>
                                )}
                                {proxyPhoto && activeChildTab === 'groundPlans' ? <ToastImageEditor image={proxyPhoto} /> : null}
                            </>
                        </Row>
                    </>
                )}
                {!fetching ? (
                    <Row className="dmn-drag-row">
                        <ReactDragListView {...dragProps} className="row" tag={Row}>
                            {groundplans?.map((item) => (
                                <AppPermissionsSwitcher
                                    permission="AllowedPhotos"
                                    photo={item}
                                    module={groundPlansModule === 'project' ? 'projects' : 'properties'}
                                    key={item.id}
                                >
                                    {({ photo }) =>
                                        photo && (
                                            <Col sm={12} xxl={3} className="drag-node">
                                                <PhotoCard
                                                    checkboxClassName="materialsGroundPlanCheckbox"
                                                    handleFullSizeClick={handleFullscreenImg}
                                                    handleCheckboxes={handlePhotosCheckboxes}
                                                    photo={photo}
                                                    validation={validation}
                                                    localeOptions={localeOptions}
                                                    handlePhotoSync={(id) => {
                                                        dispatch(syncGroundPlan(id))
                                                    }}
                                                    subform="groundplans"
                                                />
                                            </Col>
                                        )
                                    }
                                </AppPermissionsSwitcher>
                            ))}
                        </ReactDragListView>
                    </Row>
                ) : (
                    <Loader />
                )}
            </FormMainContainer>
            <DeleteModal show={deleteModal} onDeleteClick={() => handleDeleteInvoice()} onCloseClick={() => setDeleteModal(false)} />
            <DeleteModal
                show={deleteModalMulti}
                onDeleteClick={() => {
                    deleteMultiple()
                    setDeleteModalMulti(false)
                }}
                onCloseClick={() => setDeleteModalMulti(false)}
            />
        </Row>
    )
}
export default GroundPlansFormTab
