import { call, put, takeLatest } from 'redux-saga/effects'
import { fetchActivityValuesType } from './activityValues'
import { fetchAgents } from './agents'
import { fetchApplicationSettingsSaga } from './applicationSettings/sagas'
import { authenticateInitial, authenticateSuccess, fetchUser } from './auth'
import {
    fetchContactValuesAvailableEntities,
    fetchContactValuesCompanyDefinition,
    fetchContactValuesCompanySize,
    fetchContactValuesCoreBusiness,
    fetchContactValuesCountry,
    fetchContactValuesDefinition,
    fetchContactValuesDescription,
    fetchContactValuesEmailType,
    fetchContactValuesFinancing,
    fetchContactValuesGender,
    fetchContactValuesJobTitle,
    fetchContactValuesLegalForm,
    fetchContactValuesMartialStatus,
    fetchContactValuesPhoneType,
    fetchContactValuesPriority,
    fetchContactValuesProfile,
    fetchContactValuesProfileDuration,
    fetchContactValuesProfileRemark,
    fetchContactValuesProfileService,
    fetchContactValuesProfileStatus,
    fetchContactValuesQueryCategory,
    fetchContactValuesSource,
    fetchContactValuesStatus,
    fetchContactValuesTitle,
} from './contactValues'
import { fetchDocumentValuesType } from './documentValues'
import { fetchExportPortals, fetchExportSync, fetchExportWebsites } from './export'
import { fetchLocationsAvailableEntities, fetchLocationsCountry } from './locations'
import {
    fetchProjectValuesDevelopmentStage,
    fetchProjectValuesObjectType,
    fetchProjectValuesSize,
    fetchProjectValuesStatus,
    fetchProjectValuesType,
} from './projectValues'
import { fetchPropertyAvailableEntities } from './propertyAvailableEntities'
import { fetchPropertyServices } from './propertyServices'
import { fetchPropertySet } from './propertySet'
import { fetchPropertyTypes } from './propertyTypes'
import {
    fetchPropertyValuesAccess,
    fetchPropertyValuesAgencyCommission,
    fetchPropertyValuesCondition,
    fetchPropertyValuesDataSource,
    fetchPropertyValuesDescriptions,
    fetchPropertyValuesEnergyEfficiency,
    fetchPropertyValuesEquipment,
    fetchPropertyValuesFloor,
    fetchPropertyValuesFloorPosition,
    fetchPropertyValuesHeating,
    fetchPropertyValuesHouseType,
    fetchPropertyValuesLandRegistryStatus,
    fetchPropertyValuesMainRegister,
    fetchPropertyValuesMainRegisterDepartment,
    fetchPropertyValuesOrientation,
    fetchPropertyValuesOwnershipAcquisition,
    fetchPropertyValuesOwnershipAcquisitionProof,
    fetchPropertyValuesPermit,
    fetchPropertyValuesPosition,
    fetchPropertyValuesPrimiaryUsage,
    fetchPropertyValuesPropertyTransactionRiskDegree,
    fetchPropertyValuesPublicTransportation,
    fetchPropertyValuesPuk,
    fetchPropertyValuesPukDepartment,
    fetchPropertyValuesRegistryDepartmentKpu,
    fetchPropertyValuesRegistryDepartmentKpuSubinsert,
    fetchPropertyValuesRoomsNo,
    fetchPropertyValuesRoomsType,
    fetchPropertyValuesSpace,
    fetchPropertyValuesSpecialFeature,
    fetchPropertyValuesStatus,
    fetchPropertyValuesSupplySource,
    fetchPropertyValuesUtility,
    fetchPropertyValuesWindowsDoors,
} from './propertyValues'
import { fetchPropertyValuesStatusSaga } from './propertyValues/sagas'
import {
    fetchDetailSearchLocationsCountry,
    fetchDetailSearchPropertySubtypes,
    fetchDetailSearchPropertyValuesDescriptions,
    fetchDetailSearchPropertyValuesEquipment,
    fetchDetailSearchPropertyValuesFloor,
    fetchDetailSearchPropertyValuesSpace,
    fetchDetailSearchPropertyValuesUtility,
} from './searchValues'
import {
    fetchSettings,
    fetchSettingsPropertyFlatSubtypes,
    fetchSettingsPropertyGarageSubtypes,
    fetchSettingsPropertyHouseSubtypes,
    fetchSettingsPropertyLandSubtypes,
    fetchSettingsPropertyOfficeSpaceSubtypes,
} from './settings'
import { fetchCurrentUserSaga } from './auth/sagas'

export function* authenticatedAppSaga() {
    yield call(fetchCurrentUserSaga)
    yield call(fetchApplicationSettingsSaga)

    yield put(fetchSettingsPropertyFlatSubtypes())
    yield put(fetchSettingsPropertyHouseSubtypes())
    yield put(fetchSettingsPropertyOfficeSpaceSubtypes())
    yield put(fetchSettingsPropertyGarageSubtypes())
    yield put(fetchSettingsPropertyLandSubtypes())

    yield put(fetchAgents())
    yield put(fetchPropertyAvailableEntities())
    yield put(fetchContactValuesAvailableEntities())
    yield put(fetchLocationsAvailableEntities())
    yield put(fetchLocationsCountry())
    yield put(fetchPropertyValuesDataSource())
    yield put(fetchPropertyValuesSupplySource())
    yield put(fetchPropertyTypes())
    yield put(fetchPropertyServices())
    yield put(fetchPropertySet())
    yield put(fetchPropertyValuesPosition())
    yield put(fetchPropertyValuesRoomsType())
    yield put(fetchPropertyValuesHouseType())
    yield put(fetchPropertyValuesRoomsNo())
    yield put(fetchPropertyValuesFloor())
    yield put(fetchPropertyValuesFloorPosition())
    yield put(fetchPropertyValuesOrientation())
    yield put(fetchPropertyValuesHeating())
    yield put(fetchPropertyValuesCondition())
    yield put(fetchPropertyValuesWindowsDoors())
    yield put(fetchPropertyValuesPublicTransportation())
    yield put(fetchPropertyValuesSpecialFeature())
    yield put(fetchPropertyValuesUtility())
    yield put(fetchPropertyValuesEquipment())
    yield put(fetchPropertyValuesSpace())
    yield put(fetchPropertyValuesEnergyEfficiency())
    yield put(fetchPropertyValuesAccess())
    yield put(fetchPropertyValuesPermit())
    yield put(fetchPropertyValuesAgencyCommission())
    yield put(fetchPropertyValuesStatus())
    yield put(fetchPropertyValuesPropertyTransactionRiskDegree())
    yield put(fetchPropertyValuesMainRegister())
    yield put(fetchPropertyValuesDescriptions())
    yield put(fetchPropertyValuesPuk())
    yield put(fetchPropertyValuesPukDepartment())
    yield put(fetchPropertyValuesRegistryDepartmentKpu())
    yield put(fetchPropertyValuesLandRegistryStatus())
    yield put(fetchPropertyValuesOwnershipAcquisition())
    yield put(fetchPropertyValuesOwnershipAcquisitionProof())

    yield put(fetchContactValuesCompanySize())
    yield put(fetchContactValuesCoreBusiness())
    yield put(fetchContactValuesDefinition())
    yield put(fetchContactValuesCompanyDefinition())
    yield put(fetchContactValuesDescription())
    yield put(fetchContactValuesEmailType())
    yield put(fetchContactValuesGender())
    yield put(fetchContactValuesJobTitle())
    yield put(fetchContactValuesLegalForm())
    yield put(fetchContactValuesMartialStatus())
    yield put(fetchContactValuesPhoneType())
    yield put(fetchContactValuesPriority())
    yield put(fetchContactValuesQueryCategory())
    yield put(fetchContactValuesStatus())
    yield put(fetchContactValuesTitle())
    yield put(fetchContactValuesSource())
    yield put(fetchContactValuesFinancing())
    // yield put(fetchContactValuesProfile())
    yield put(fetchContactValuesProfileDuration())
    yield put(fetchContactValuesProfileStatus())
    yield put(fetchContactValuesProfileRemark())
    yield put(fetchContactValuesProfileService())
    yield put(fetchContactValuesCountry())

    yield put(fetchProjectValuesDevelopmentStage())
    yield put(fetchProjectValuesObjectType())
    yield put(fetchProjectValuesSize())
    yield put(fetchProjectValuesStatus())
    yield put(fetchProjectValuesType())

    yield put(fetchExportPortals())
    yield put(fetchExportWebsites())
    yield put(fetchExportSync())
    yield put(fetchActivityValuesType())
    yield put(fetchDocumentValuesType())

    yield put(fetchDetailSearchLocationsCountry())
    yield put(fetchDetailSearchPropertySubtypes())
    yield put(fetchDetailSearchPropertyValuesDescriptions())
    yield put(fetchDetailSearchPropertyValuesUtility())
    yield put(fetchDetailSearchPropertyValuesEquipment())
    yield put(fetchDetailSearchPropertyValuesSpace())
    yield put(fetchDetailSearchPropertyValuesFloor())
}

export default function* actionWatcher() {
    yield takeLatest([`${authenticateInitial}`, `${authenticateSuccess}`], authenticatedAppSaga)
}
