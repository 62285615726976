import { getIn } from 'formik'
import _ from 'lodash'
import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Card, CardBody, Row } from 'reactstrap'
import { propertyTransactionsFields } from '../../common/forms/generalRealEstate/fields'
import { fetchPropertyValuesMainRegisterDepartment, fetchPropertyValuesRegistryDepartmentKpuSubinsert } from '../../store/propertyValues'
import AppPermissionsSwitcher from '../AppPermissionsSwitcher/AppPermissionsSwitcher'
import FormMainContainer from '../Common/FormMainContainer'
import FormSideContainer from '../Common/FormSideContainer'
import RecursiveForm from '../RecursiveForm'
import PropertyFormTransactionsContext from './PropertyFormTransactionsContext'

const PropertyFormTransactionsTab = ({ children, ...props }) => {
    return (
        <PropertyFormTransactionsContext.Provider
            value={{
                tabs: props.tabs,
                activeTab: props.activeTab,
                handleTabChange: props.handleTabChange,
                formik: props.formik,
                inputs: props.inputs,
                subform: props.subform,
                selectOptions: props.selectOptions,
                tabSideData: props.tabSideData,
                module: props.module,
            }}
        >
            <Row>{children}</Row>
        </PropertyFormTransactionsContext.Provider>
    )
}

export const PropertyFormTransactionsTabSide = () => {
    const { inputs, subform, formik, selectOptions, tabSideData, module } = useContext(PropertyFormTransactionsContext)
    const { propertyService, propertyStatus } = tabSideData ?? {}
    const disableByIdArray = [3, 4, 8, 9]
    let sideForm = {}

    if (inputs?.sideForm) {
        sideForm = { ...inputs?.sideForm }

        if (disableByIdArray.includes(formik.values.transactions.property_status_id)) sideForm.disabled = true
    }

    const inputsArray = _.values(sideForm)

    let filteredArray = []
    let filteredSelectOptions = Object.assign({}, selectOptions)
    delete filteredSelectOptions.property_status_id

    const filterPropertyStatuses = (array) => {
        filteredArray = selectOptions.property_status_id.filter((item) => array.includes(item.value.toString()))
    }

    const activePropertySellingStatuses = ['1', '2', '3', '5', '7', '8', '10']
    const inactivePropertySellingStatuses = ['3', '8']

    const activePropertyRentingStatuses = ['1', '2', '4', '6', '7', '9', '10']
    const inactivePropertyRentingStatuses = ['4', '9']

    // propertyService.id: 1 (Prodaja)
    if (propertyService?.id === 1) {
        if (inactivePropertySellingStatuses.includes(propertyStatus?.id.toString())) {
            filterPropertyStatuses(inactivePropertySellingStatuses)
        } else {
            filterPropertyStatuses(activePropertySellingStatuses)
        }
    }

    // propertyService.id: 2 (Najam/Zakup)
    if (propertyService?.id === 2) {
        if (inactivePropertyRentingStatuses.includes(propertyStatus?.id.toString())) {
            filterPropertyStatuses(inactivePropertyRentingStatuses)
        } else {
            filterPropertyStatuses(activePropertyRentingStatuses)
        }
    }

    filteredSelectOptions['property_status_id'] = filteredArray

    return (
        <FormSideContainer>
            <Card>
                <CardBody>
                    <AppPermissionsSwitcher permission="PropertyStatus" module={module} selectOptions={filteredSelectOptions}>
                        {({ newSelectOptions }) => <RecursiveForm inputs={inputsArray} subform={subform} formik={formik} selectOptions={newSelectOptions} />}
                    </AppPermissionsSwitcher>
                </CardBody>
            </Card>
        </FormSideContainer>
    )
}

export const PropertyFormTransactionsTabMain = () => {
    const { inputs, formik, subform, tabs, activeTab, handleTabChange, selectOptions, module } = useContext(PropertyFormTransactionsContext)
    const dispatch = useDispatch()

    const pmrValue = getIn(formik.values?.transactions, propertyTransactionsFields.PROPERTY_MAIN_REGISTER_ID)
    const prdkValue = getIn(formik.values?.[subform], propertyTransactionsFields.PROPERTY_REGISTRY_DEPARTMENT_KPU_ID)

    const mainRegistryGroupIndex = inputs.mainForm?.findIndex((obj) => obj.name === 'mainRegistryGroup')
    const registryDepartmentGroupIndex = inputs.mainForm?.findIndex((obj) => obj.name === 'registryDepartmentGroup')

    useEffect(() => {
        if (inputs.mainForm) {
            const mainRegisterDepartmentIndex = inputs.mainForm?.[mainRegistryGroupIndex].children.findIndex(
                (obj) => obj.name === propertyTransactionsFields.PROPERTY_MAIN_REGISTER_DEPARTMENT_ID
            )
            if (mainRegistryGroupIndex !== -1) {
                if (mainRegisterDepartmentIndex !== -1) {
                    inputs.mainForm[mainRegistryGroupIndex].children[mainRegisterDepartmentIndex].disabled = pmrValue ? false : true
                }
            }

            dispatch(fetchPropertyValuesMainRegisterDepartment(pmrValue))
        }
    }, [dispatch, inputs.mainForm, mainRegistryGroupIndex, pmrValue])

    useEffect(() => {
        if (inputs.mainForm) {
            const registryDepartmentKpuSubinsertIndex = inputs.mainForm?.[registryDepartmentGroupIndex].children.findIndex(
                (obj) => obj.name === propertyTransactionsFields.PROPERTY_REGISTRY_DEPARTMENT_KPU_SUBINSERT_ID
            )

            if (registryDepartmentKpuSubinsertIndex !== -1) {
                inputs.mainForm[registryDepartmentGroupIndex].children[registryDepartmentKpuSubinsertIndex].disabled = prdkValue ? false : true
                inputs.mainForm[registryDepartmentGroupIndex].children[registryDepartmentKpuSubinsertIndex].disabled === true &&
                    (formik.values.transactions.property_registry_department_kpu_subinsert_id = '')
            }

            dispatch(fetchPropertyValuesRegistryDepartmentKpuSubinsert(prdkValue))
        }
    }, [dispatch, inputs.mainForm, prdkValue, registryDepartmentGroupIndex])

    if (inputs.mainForm) {
        if (mainRegistryGroupIndex !== -1) {
            const mainRegisterIndex = inputs.mainForm[mainRegistryGroupIndex].children.findIndex(
                (obj) => obj.name === propertyTransactionsFields.PROPERTY_MAIN_REGISTER_ID
            )

            if (mainRegisterIndex !== -1) {
                inputs.mainForm[mainRegistryGroupIndex].children[mainRegisterIndex].handleChange = (id) =>
                    dispatch(fetchPropertyValuesMainRegisterDepartment(id))
            }
        }
        if (registryDepartmentGroupIndex !== -1) {
            const registryDepartmentKpuIndex = inputs.mainForm[registryDepartmentGroupIndex].children.findIndex(
                (obj) => obj.name === propertyTransactionsFields.PROPERTY_REGISTRY_DEPARTMENT_KPU_ID
            )

            if (registryDepartmentKpuIndex !== -1) {
                inputs.mainForm[registryDepartmentGroupIndex].children[registryDepartmentKpuIndex].handleChange = (id) =>
                    dispatch(fetchPropertyValuesRegistryDepartmentKpuSubinsert(id))
            }
        }
    }

    return (
        <FormMainContainer ainContainer tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange}>
            <AppPermissionsSwitcher permission="EditData" data={inputs?.mainForm} module={module}>
                {({ newData }) => <RecursiveForm inputs={newData} subform={subform} formik={formik} selectOptions={selectOptions} />}
            </AppPermissionsSwitcher>
        </FormMainContainer>
    )
}

export default PropertyFormTransactionsTab
