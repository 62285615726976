import QueryString from 'qs'
import { toast } from 'react-toastify'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { SUBMIT_RESPONSE_STATUS } from '../../common/constants'
import { projectResponseFields } from '../../common/response/project'
import { findEntityTitle, generateLocationData } from '../../utils/general'
import { apiRequest } from '../api'
import i18n from '../../i18n'
import {
    createProject,
    createProjectFailure,
    createProjectNote,
    createProjectNoteFailure,
    createProjectNoteRequest,
    createProjectNoteSuccess,
    createProjectRequest,
    createProjectSuccess,
    deleteProject,
    deleteProjectFailure,
    deleteProjectMultiple,
    deleteProjectMultipleFailure,
    deleteProjectMultipleRequest,
    deleteProjectMultipleSuccess,
    deleteProjectNote,
    deleteProjectNoteFailure,
    deleteProjectNoteRequest,
    deleteProjectNoteSuccess,
    deleteProjectRequest,
    deleteProjectSuccess,
    fetchProject,
    fetchProjectFailure,
    fetchProjectNoStore,
    fetchProjectNoStoreFailure,
    fetchProjectNoStoreRequest,
    fetchProjectNoStoreSuccess,
    fetchProjectNotes,
    fetchProjectNotesFailure,
    fetchProjectNotesRequest,
    fetchProjectNotesSuccess,
    fetchProjectRequest,
    fetchProjectSuccess,
    fetchProjects,
    fetchProjectsAsyncInput,
    fetchProjectsAsyncInputContactFailure,
    fetchProjectsAsyncInputContactRequest,
    fetchProjectsAsyncInputContactSuccess,
    fetchProjectsFailure,
    fetchProjectsNoStore,
    fetchProjectsNoStoreFailure,
    fetchProjectsNoStoreRequest,
    fetchProjectsNoStoreSuccess,
    fetchProjectsRequest,
    fetchProjectsSuccess,
    fetchTemplatesNoStore,
    fetchTemplatesNoStoreFailure,
    fetchTemplatesNoStoreRequest,
    fetchTemplatesNoStoreSuccess,
    updateProject,
    updateProjectFailure,
    updateProjectNote,
    updateProjectNoteFailure,
    updateProjectNoteRequest,
    updateProjectNoteSuccess,
    updateProjectRequest,
    updateProjectSuccess,
} from './actions'

export function* fetchProjectsSaga({ payload, meta }) {
    const params = payload
    yield put(fetchProjectsRequest())
    try {
        const response = yield apiRequest(`v1/project`, {
            method: 'GET',
            params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchProjectsSuccess(response.data))
        meta(response.data.count)
    } catch (error) {
        yield put(fetchProjectsFailure(error))
    }
}

export function* fetchProjectSaga({ payload }) {
    const { id, order, criteria } = payload

    yield put(fetchProjectRequest())

    try {
        const response = yield apiRequest(`v1/project/${id}`, {
            method: 'GET',
            params: { order, criteria },
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchProjectSuccess(response.data))
    } catch (error) {
        yield put(fetchProjectFailure(error))
    }
}

export function* createProjectSaga({ payload, meta }) {
    yield put(createProjectRequest())
    const progressToast = toast.loading(i18n.t('toast.loading'))
    try {
        const response = yield apiRequest('v1/project', {
            method: 'POST',
            data: payload,
        })
        yield put(createProjectSuccess(response))
        // console.log('project response', response)
        if (meta && meta.callback) {
            meta.callback({
                submitStatus: SUBMIT_RESPONSE_STATUS.SUCCESS,
                data: response?.data,
            })
        }
        toast.update(progressToast, { render: i18n.t('toast.update.success'), type: 'success', isLoading: false, autoClose: 3000 })
    } catch (error) {
        // console.log('create project sga', error)
        yield put(createProjectFailure(error))
        toast.update(progressToast, { render: i18n.t('toast.update.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }

    // if(meta && meta.callback){
    //     meta.callback();
    // }
}

export function* updateProjectSaga({ payload, meta }) {
    yield put(updateProjectRequest())
    const { id, ...data } = payload
    const progressToast = toast.loading(i18n.t('toast.loading'))
    try {
        const response = yield apiRequest(`v1/project/${id}`, {
            method: 'PUT',
            data: data,
        })
        yield put(updateProjectSuccess(response))
        if (meta && meta.callback) {
            meta.callback({
                submitStatus: SUBMIT_RESPONSE_STATUS.SUCCESS,
            })
        }
        toast.update(progressToast, { render: i18n.t('toast.update.success'), type: 'success', isLoading: false, autoClose: 3000 })
    } catch (error) {
        yield put(updateProjectFailure(error))
        toast.update(progressToast, { render: i18n.t('toast.update.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }

    // if(meta && meta.callback){
    //     meta.callback();
    // }
}

export function* fetchProjectsAsyncInputSaga({ payload, meta }) {
    const params = payload.params
    yield put(fetchProjectsAsyncInputContactRequest())
    try {
        let response = yield apiRequest(`v1/project`, {
            method: 'GET',
            params: params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
   
        yield put(fetchProjectsAsyncInputContactSuccess())

        let formatedResults
        if (response.data.items) {
            formatedResults = response.data.items?.map((item) => {
                return {
                    value: item.id,
                    label: `ID: ${item.id} - ${findEntityTitle(item, projectResponseFields.PROJECT_TITLE)}`,
                    location: generateLocationData(item)
                }
            })
        } else {
            if (response.data.contactType === 'company') {
                formatedResults = [
                    {
                        value: response.data.id,
                        label: response.data.contactCompany?.name,
                        selected: payload?.initialValue ? true : false,
                    },
                ]
            } else {
                formatedResults = [
                    {
                        value: response.data.id,
                        label: response.data.name,
                    },
                ]
            }
        }
    
        meta(formatedResults)
    } catch (error) {
        yield put(fetchProjectsAsyncInputContactFailure(error))
    }
}

export function* deleteProjectSaga({ payload, meta }) {
    yield put(deleteProjectRequest())
    const progressToast = toast.loading(i18n.t('toast.loading.delete'))

    try {
        const response = yield apiRequest(`v1/project/${payload}`, {
            method: 'DELETE',
        })
        yield put(deleteProjectSuccess(payload))
        toast.update(progressToast, { render: i18n.t('toast.update.delete.success'), type: 'success', isLoading: false, autoClose: 3000 })

        if (meta && meta.redirect) {
            meta.redirect()
        }
    } catch (error) {
        yield put(deleteProjectFailure(error))
        toast.update(progressToast, { render: i18n.t('toast.update.delete.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }
}

function* deleteProjectById(id) {
    try {
        const response = yield apiRequest(`v1/project/${id}`, {
            method: 'DELETE',
        })
        return response
    } catch (error) {
        return error
    }
}

export function* deleteProjectMultipleSaga({ payload }) {
    const requestsToBeDeletedCount = payload.length
    yield put(deleteProjectMultipleRequest())

    if (requestsToBeDeletedCount === 0) {
        yield put(deleteProjectMultipleFailure('Nema projekata za brisanje'))
        return
    }

    const progressToast = toast.loading(i18n.t('toast.loading.delete.number', {requestsToBeDeletedCount}))

    const responses = yield all(payload.map((id) => call(deleteProjectById, id)))

    const failedResponses = responses.filter((response) => response instanceof Error)
    const deletedProjects = responses.filter((response) => response.status === 200).map((response) => response.data.id)
    const successCount = deletedProjects.length
    const failedCount = failedResponses.length

    if (successCount === 0 && failedCount > 0) {
        yield put(deleteProjectMultipleFailure('Brisanje nije uspjelo'))
        toast.update(progressToast, {
            render: i18n.t('toast.loading.delete'),
            type: 'error',
            isLoading: false,
            autoClose: 3000,
        })
        return
    }

    if (successCount > 0) {
        toast.update(progressToast, {
            render: i18n.t('toast.loading.delete.number.success', {successCount, requestsToBeDeletedCount}),
            type: 'success',
            isLoading: false,
            autoClose: 3000,
        })
    }

    if (failedCount > 0) {
        toast.error(i18n.t('toast.loading.delete.number.error', {failedCount, requestsToBeDeletedCount}), {
            autoClose: 3000,
        })
    }

    yield put(deleteProjectMultipleSuccess(deletedProjects))
}

export function* fetchProjectsNoStoreSaga({ payload, meta }) {
    const params = payload
    yield put(fetchProjectsNoStoreRequest())
    try {
        const response = yield apiRequest(`v1/project`, {
            method: 'GET',
            params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        yield put(fetchProjectsNoStoreSuccess())
        if (meta && meta.callback) {
            meta.callback({
                submitStatus: SUBMIT_RESPONSE_STATUS.SUCCESS,
                data: response.data,
            })
        }
    } catch (error) {
        yield put(fetchProjectsNoStoreFailure(error))
        meta.callback({
            submitStatus: SUBMIT_RESPONSE_STATUS.FAILURE,
        })
    }
}

export function* fetchTemplatesNoStoreSaga({ meta }) {
    yield put(fetchTemplatesNoStoreRequest())
    try {
        const response = yield apiRequest(`v1/offer/templates/project`, {
            method: 'GET',
        })
        yield put(fetchTemplatesNoStoreSuccess())
        meta(response.data)
    } catch (error) {
        yield put(fetchTemplatesNoStoreFailure(error))
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchProjects}`, fetchProjectsSaga)
    yield takeLatest(`${fetchProject}`, fetchProjectSaga)
    yield takeEvery(`${createProject}`, createProjectSaga)
    yield takeEvery(`${updateProject}`, updateProjectSaga)
    yield takeEvery(`${deleteProject}`, deleteProjectSaga)
    yield takeEvery(`${deleteProjectMultiple}`, deleteProjectMultipleSaga)
    yield takeLatest(`${fetchProjectsAsyncInput}`, fetchProjectsAsyncInputSaga)
    yield takeLatest(`${fetchProjectsNoStore}`, fetchProjectsNoStoreSaga)
    yield takeLatest(`${fetchTemplatesNoStore}`, fetchTemplatesNoStoreSaga)
}
