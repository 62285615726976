import { getIn } from 'formik'
import { useEffect, useState } from 'react'
import ReactDragListView from 'react-drag-listview/lib/index.js'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AccordionItem, Button, Card, CardBody, Col, Collapse, Input, Label, Row } from 'reactstrap'
import { photosFields } from '../../common/forms/photos/fields'
import { clearGroundPlans } from '../../store/groundplans'
import {
    clearPhotos,
    deletePhoto,
    fetchPhotos,
    proxyPhotoEdit,
    proxyPhotoEditClear,
    reorderPhotos,
    selectPhotos,
    selectPhotosFetching,
    selectPhotosProxyPhoto,
    selectPhotosProxyPhotoFetching,
    syncPhoto,
    updatePhotoPriority,
    uploadPhotos,
} from '../../store/photos'
import { selectProject } from '../../store/projects'
import { selectProperty } from '../../store/properties'
import AppPermissionsSwitcher from '../AppPermissionsSwitcher/AppPermissionsSwitcher'
import DeleteModal from '../Common/DeleteModal'
import FormMainContainer from '../Common/FormMainContainer'
import FormSideContainer from '../Common/FormSideContainer'
import FormTabNavigation from '../Common/FormTabNavigation'
import Loader from '../Common/Loader'
import Dropzone from '../Dropzone/Dropzone'
import useLocaleOptions from '../Hooks/useLocaleOptions'
import PhotoCard from '../PhotoCard'
import ToastImageEditor from '../ToastImageEditor/ToastImageEditor'
import { API_URL } from '../../common/constants'

const PhotosFormTab = ({
    photosModule,
    parentId,
    tabs = [],
    activeTab,
    handleTabChange,
    childTabs = [],
    activeChildTab,
    handleChildTabChange,
    validation,
}) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const localeOptions = useLocaleOptions()

    const { photos, entity, proxyPhoto, proxyPhotoFetching, fetching } = useSelector((state) => ({
        photos: selectPhotos(state),
        fetching: selectPhotosFetching(state),
        entity: photosModule === 'project' ? selectProject(state) : selectProperty(state),
        proxyPhoto: selectPhotosProxyPhoto(state),
        proxyPhotoFetching: selectPhotosProxyPhotoFetching(state),
    }))

    const [fullscreenImg, setFullscreenImg] = useState(null)
    const [selectedPhotos, setSelectedPhotos] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteModalMulti, setDeleteModalMulti] = useState(false)
    const [invoice, setInvoice] = useState(null)
    const [photosTabClicked, setPhotosTabClicked] = useState(false)

    useEffect(() => {
        dispatch(clearPhotos())
        dispatch(clearGroundPlans())
    }, [dispatch])

    useEffect(() => {
        if (activeTab === 'materials') {
            window.scrollTo(0, 0)
        }
    }, [activeTab])

    useEffect(() => {
        if (activeTab !== 'materials' || activeChildTab !== 'photos') {
            dispatch(proxyPhotoEditClear())
            setFullscreenImg(null)
        }
    }, [activeTab, activeChildTab, dispatch])

    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            dispatch(
                reorderPhotos({
                    fromIndex,
                    toIndex,
                })
            )
        },
        nodeSelector: '.drag-node',
        handleSelector: '.mdi-menu',
        lineClassName: 'dmn-photo-drag',
    }

    const onClickDelete = () => {
        if (selectedPhotos.length) {
            setDeleteModalMulti(true)
            return
        }

        setInvoice(fullscreenImg)
        setDeleteModal(true)
        dispatch(proxyPhotoEditClear())
    }

    // TODO: seems like this function is never used
    const handleDeleteInvoice = () => {
        if (invoice) {
            dispatch(deletePhoto(invoice.id))
            setDeleteModal(false)
            setFullscreenImg(null)
            setSelectedPhotos([])
        }
    }

    const deleteMultiple = () => {
        for (const [i, id] of selectedPhotos.entries()) {
            dispatch(deletePhoto({ id, entity }))
        }

        setSelectedPhotos([])
        dispatch(updatePhotoPriority(selectedPhotos))
    }

    useEffect(() => {
        const isPhotosClicked = activeTab === 'materials' && activeChildTab === 'photos'

        if (isPhotosClicked) {
            setPhotosTabClicked(true)
        } else {
            setPhotosTabClicked(false)
        }
    }, [activeTab, activeChildTab])

    useEffect(() => {
        if (photosTabClicked && !photos.length) {
            dispatch(
                fetchPhotos(
                    {
                        ...(parentId ? { parentId } : {}),
                        noLimit: 1,
                        order: { priority: 'asc' },
                        criteria: {
                            collection: 'photo',
                            ...(photosModule ? { module: photosModule } : {}),
                        },
                    },
                    (count) => {
                        // console.log('count from meta', count)
                        // setPageCount(Math.floor(count / limit))
                    }
                )
            )
        }
    }, [dispatch, parentId, photosModule, photosTabClicked, photos.length])

    useEffect(() => {
        for (const [i, k] of photos.entries()) {
            const photoItem = getIn(validation.values.photos, `${k.id}`)
            if (!photoItem) {
                validation?.setFieldValue(`photos.${k.id}`, {
                    id: k.id,
                    [photosFields.PRIORITY]: k.priority,
                })
            }
        }
    }, [photos])

    const handlePhotosUpload = (files) => {
        dispatch(
            uploadPhotos({
                files,
                entity,
                photosModule,
            })
        )
    }

    const handleFullscreenImg = (img) => {
        setFullscreenImg(img)

        handleProxyPhotoEdit(img)
        deselectPhotos()
    }

    const handleProxyPhotoEdit = (img) => {
        if (img) {
            dispatch(proxyPhotoEdit(img.id))
        } else {
            dispatch(proxyPhotoEditClear())
        }
    }

    const deselectPhotos = () => {
        const inputs = document.querySelectorAll('.materialsPhotoCheckbox:checked')
        inputs.forEach((input) => (input.checked = false))
        setSelectedPhotos([])
    }

    const handlePhotosCheckboxes = () => {
        let inputs
        let values = []
        switch (activeChildTab) {
            case 'photos':
                inputs = document.querySelectorAll('.materialsPhotoCheckbox:checked')
                inputs.forEach((element) => {
                    values.push(element.value)
                })
                setSelectedPhotos(values)
                dispatch(proxyPhotoEditClear())
                break
            default:
                break
        }
    }

    const renderDownloadUrl = () => {
        let dlUrl = `${API_URL}v1/file/download/`
        let selectedIds = selectedPhotos.map((x) => parseInt(x))

        if (fullscreenImg && fullscreenImg.id) {
            selectedIds.push(fullscreenImg.id)
        }
        let uniqueIds = [...new Set(selectedIds)]

        dlUrl = dlUrl + uniqueIds.join(',')
        return dlUrl
    }

    const checkedAll = () => {
        let ele = document.querySelectorAll('.materialsPhotoCheckbox')

        if (!selectedPhotos.length) {
            let values = []

            ele.forEach((ele) => {
                ele.checked = true
                values.push(ele.value)
            })

            setSelectedPhotos(values)
        } else {
            setSelectedPhotos([])

            ele.forEach((ele) => {
                ele.checked = false
            })
        }
    }

    return (
        <Row>
            <FormSideContainer>{activeTab === 'materials' && <Dropzone callback={handlePhotosUpload} tab={activeChildTab} />}</FormSideContainer>

            <FormMainContainer tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange}>
                <div className="d-flex justify-content-between align-items-center">
                    {childTabs.length ? (
                        <FormTabNavigation
                            handleTabChange={handleChildTabChange}
                            tabs={childTabs}
                            activeTab={activeChildTab}
                            customClass="real-estate-form-subtabs mb-4"
                        />
                    ) : (
                        ''
                    )}

                    {/* action buttons: editor close, selectAll, delete, download */}
                    <div className="hstack gap-2 d-print-none">
                        {proxyPhoto && (
                            <Button
                                onClick={(e) => {
                                    e.preventDefault()
                                    handleFullscreenImg(null)
                                }}
                                color="dark"
                                className="btn-icon"
                                style={{ zIndex: 2 }}
                            >
                                <i className="mdi mdi-close" />{' '}
                            </Button>
                        )}
                        <Button
                            color="warning"
                            className="btn-label"
                            disabled={!!proxyPhoto}
                            onClick={(e) => {
                                e.preventDefault()
                                checkedAll()
                            }}
                        >
                            <i className="mdi mdi-checkbox-multiple-outline label-icon align-middle fs-16 me-2"></i>{' '}
                            {selectedPhotos.length ? t('button.deselect') : t('button.select')}
                        </Button>
                        {selectedPhotos.length ? (
                            <>
                                <Button
                                    color="danger"
                                    className="btn-label"
                                    disabled={fullscreenImg === null && selectedPhotos.length === 0}
                                    onClick={() => {
                                        onClickDelete()
                                    }}
                                >
                                    <i className="mdi mdi-trash-can-outline label-icon align-middle fs-16 me-2"></i> {t('button.delete')}
                                </Button>
                                <Button
                                    tag="a"
                                    href={renderDownloadUrl()}
                                    color="edit"
                                    className="btn-label"
                                    download={true}
                                    disabled={fullscreenImg === null && !selectedPhotos.length}
                                >
                                    <i className="mdi mdi-download label-icon align-middle fs-16 me-2"></i>
                                    {t('button.download')}
                                </Button>
                            </>
                        ) : null}
                    </div>
                </div>

                {/* image editor display */}
                {(proxyPhotoFetching || proxyPhoto) && (
                    <>
                        <Row className="mb-5 position-relative">
                            <>
                                {proxyPhotoFetching && (
                                    <div className="large-spinner-container">
                                        <Loader />
                                    </div>
                                )}
                                {proxyPhoto && activeChildTab === 'photos' ? <ToastImageEditor image={proxyPhoto} /> : null}
                            </>
                        </Row>
                    </>
                )}

                {/* photos display */}
                {!fetching ? (
                    <Row className="dmn-drag-row">
                        <ReactDragListView {...dragProps} className="row" tag={Row}>
                            {photos.map((item) => (
                                <AppPermissionsSwitcher
                                    permission="AllowedPhotos"
                                    photo={item}
                                    module={photosModule === 'project' ? 'projects' : 'properties'}
                                    key={item.id}
                                >
                                    {({ photo }) =>
                                        photo && (
                                            <Col sm={12} xxl={3} className="drag-node">
                                                <PhotoCard
                                                    handleFullSizeClick={handleFullscreenImg}
                                                    handleCheckboxes={handlePhotosCheckboxes}
                                                    photo={photo}
                                                    validation={validation}
                                                    localeOptions={localeOptions}
                                                    handlePhotoSync={(id) => {
                                                        dispatch(syncPhoto(id))
                                                    }}
                                                />
                                            </Col>
                                        )
                                    }
                                </AppPermissionsSwitcher>
                            ))}
                        </ReactDragListView>
                    </Row>
                ) : (
                    <Loader />
                )}
            </FormMainContainer>

            <DeleteModal show={deleteModal} onDeleteClick={() => handleDeleteInvoice()} onCloseClick={() => setDeleteModal(false)} />
            <DeleteModal
                show={deleteModalMulti}
                onDeleteClick={() => {
                    deleteMultiple()
                    setDeleteModalMulti(false)
                }}
                onCloseClick={() => setDeleteModalMulti(false)}
            />
        </Row>
    )
}

export default PhotosFormTab
