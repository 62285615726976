import { createAction } from 'redux-actions';

const ENTITY = 'PROPERTYVALUES';
const POSITION = 'POSITION';
const ROOMSTYPE = 'ROOMSTYPE';
const HOUSETYPE= 'HOUSETYPE';
const ROOMSNO = 'ROOMSNO';
const FLOOR = 'FLOOR';
const FLOORPOSITION = 'FLOORPOSITION';
const ORIENTATION = 'ORIENTATION';
const HEATING = 'HEATING';
const CONDITION = 'CONDITION';
const WINDOWSDOORS = 'WINDOWSDOORS';
const PUBLICTRANSPORTATION = 'PUBLICTRANSPORATION';
const SPECIALFEATURE = 'SPECIALFEATURE';
const UTILITY = 'UTILITY';
const EQUIPMENT = 'EQUIPMENT';
const SPACE = 'SPACE';
const ENERGYEFFICIENCY = 'ENERGYEFFICIENCY';
const ACCESS = 'ACCESS';
const PERMIT = 'PERMIT';
const AGENCYCOMMISSION = 'AGENCYCOMMISSION';
const STATUS = "STATUS";
const PROPERTYTRANSACTIONRISKDEGREE = "PROPERTYTRANSACTIONRISKDEGREE";
const MAINREGISTER = "MAINREGISTER";
const DESCRIPTIONS = "DESCRIPTIONS";
const MAINREGISTERDEPARTMENT = 'MAINREGISTERDEPARTMENT';
const PUK = "PUK";
const PUKDEPARTMENT = "PUKDEPARTMENT";
const REGISTRYDEPARTMENTKPU = "REGISTRYDEPARTMENTKPU";
const REGISTRYDEPARTMENTKPUSUBINSERT = "REGISTRYDEPARTMENTKPUSUBINSERT";
const DATASOURCE = 'DATASOURCE';
const PROPERTYLANDREGISTRYSTATUS = 'PROPERTYLANDREGISTRYSTATUS';
const PROPERTYOWNERSHIPACQUISITION = "PROPERTYOWNERSHIPACQUISITION";
const PROPERTYOWNERSHIPACQUISITIONPROOF = "PROPERTYOWNERSHIPACQUISITIONPROOF";
const PRIMIARYUSAGE = "PRIMIARYUSAGE";
const LANDUSAGE = "LANDUSAGE";
const SUPPLYSOURCE = "SUPPLYSOURCE";

export const stateKey = ENTITY.toLowerCase();

export const fetchPropertyValuesPosition = createAction(`${ENTITY}_${POSITION}/FETCH`);
export const fetchPropertyValuesPositionRequest = createAction(`${ENTITY}_${POSITION}/FETCH_REQUEST`);
export const fetchPropertyValuesPositionSuccess = createAction(`${ENTITY}_${POSITION}/FETCH_SUCCESS`);
export const fetchPropertyValuesPositionFailure = createAction(`${ENTITY}_${POSITION}/FETCH_FAILURE`);

export const fetchPropertyValuesRoomsType = createAction(`${ENTITY}_${ROOMSTYPE}/FETCH`);
export const fetchPropertyValuesRoomsTypeRequest = createAction(`${ENTITY}_${ROOMSTYPE}/FETCH_REQUEST`);
export const fetchPropertyValuesRoomsTypeSuccess = createAction(`${ENTITY}_${ROOMSTYPE}/FETCH_SUCCESS`);
export const fetchPropertyValuesRoomsTypeFailure = createAction(`${ENTITY}_${ROOMSTYPE}/FETCH_FAILURE`);

export const fetchPropertyValuesHouseType = createAction(`${ENTITY}_${HOUSETYPE}/FETCH`);
export const fetchPropertyValuesHouseTypeRequest = createAction(`${ENTITY}_${HOUSETYPE}/FETCH_REQUEST`);
export const fetchPropertyValuesHouseTypeSuccess = createAction(`${ENTITY}_${HOUSETYPE}/FETCH_SUCCESS`);
export const fetchPropertyValuesHouseTypeFailure = createAction(`${ENTITY}_${HOUSETYPE}/FETCH_FAILURE`);

export const fetchPropertyValuesRoomsNo = createAction(`${ENTITY}_${ROOMSNO}/FETCH`);
export const fetchPropertyValuesRoomsNoRequest = createAction(`${ENTITY}_${ROOMSNO}/FETCH_REQUEST`);
export const fetchPropertyValuesRoomsNoSuccess = createAction(`${ENTITY}_${ROOMSNO}/FETCH_SUCCESS`);
export const fetchPropertyValuesRoomsNoFailure = createAction(`${ENTITY}_${ROOMSNO}/FETCH_FAILURE`);

export const fetchPropertyValuesFloor = createAction(`${ENTITY}_${FLOOR}/FETCH`);
export const fetchPropertyValuesFloorRequest = createAction(`${ENTITY}_${FLOOR}/FETCH_REQUEST`);
export const fetchPropertyValuesFloorSuccess = createAction(`${ENTITY}_${FLOOR}/FETCH_SUCCESS`);
export const fetchPropertyValuesFloorFailure = createAction(`${ENTITY}_${FLOOR}/FETCH_FAILURE`);

export const fetchPropertyValuesFloorPosition = createAction(`${ENTITY}_${FLOORPOSITION}/FETCH`);
export const fetchPropertyValuesFloorPositionRequest = createAction(`${ENTITY}_${FLOORPOSITION}/FETCH_REQUEST`);
export const fetchPropertyValuesFloorPositionSuccess = createAction(`${ENTITY}_${FLOORPOSITION}/FETCH_SUCCESS`);
export const fetchPropertyValuesFloorPositionFailure = createAction(`${ENTITY}_${FLOORPOSITION}/FETCH_FAILURE`);

export const fetchPropertyValuesOrientation = createAction(`${ENTITY}_${ORIENTATION}/FETCH`);
export const fetchPropertyValuesOrientationRequest = createAction(`${ENTITY}_${ORIENTATION}/FETCH_REQUEST`);
export const fetchPropertyValuesOrientationSuccess = createAction(`${ENTITY}_${ORIENTATION}/FETCH_SUCCESS`);
export const fetchPropertyValuesOrientationFailure = createAction(`${ENTITY}_${ORIENTATION}/FETCH_FAILURE`);

export const fetchPropertyValuesHeating = createAction(`${ENTITY}_${HEATING}/FETCH`);
export const fetchPropertyValuesHeatingRequest = createAction(`${ENTITY}_${HEATING}/FETCH_REQUEST`);
export const fetchPropertyValuesHeatingSuccess = createAction(`${ENTITY}_${HEATING}/FETCH_SUCCESS`);
export const fetchPropertyValuesHeatingFailure = createAction(`${ENTITY}_${HEATING}/FETCH_FAILURE`);

export const fetchPropertyValuesCondition = createAction(`${ENTITY}_${CONDITION}/FETCH`);
export const fetchPropertyValuesConditionRequest = createAction(`${ENTITY}_${CONDITION}/FETCH_REQUEST`);
export const fetchPropertyValuesConditionSuccess = createAction(`${ENTITY}_${CONDITION}/FETCH_SUCCESS`);
export const fetchPropertyValuesConditionFailure = createAction(`${ENTITY}_${CONDITION}/FETCH_FAILURE`);

export const fetchPropertyValuesWindowsDoors = createAction(`${ENTITY}_${WINDOWSDOORS}/FETCH`);
export const fetchPropertyValuesWindowsDoorsRequest = createAction(`${ENTITY}_${WINDOWSDOORS}/FETCH_REQUEST`);
export const fetchPropertyValuesWindowsDoorsSuccess = createAction(`${ENTITY}_${WINDOWSDOORS}/FETCH_SUCCESS`);
export const fetchPropertyValuesWindowsDoorsFailure = createAction(`${ENTITY}_${WINDOWSDOORS}/FETCH_FAILURE`);

export const fetchPropertyValuesPublicTransportation = createAction(`${ENTITY}_${PUBLICTRANSPORTATION}/FETCH`);
export const fetchPropertyValuesPublicTransportationRequest = createAction(`${ENTITY}_${PUBLICTRANSPORTATION}/FETCH_REQUEST`);
export const fetchPropertyValuesPublicTransportationSuccess = createAction(`${ENTITY}_${PUBLICTRANSPORTATION}/FETCH_SUCCESS`);
export const fetchPropertyValuesPublicTransportationFailure = createAction(`${ENTITY}_${PUBLICTRANSPORTATION}/FETCH_FAILURE`);

export const fetchPropertyValuesSpecialFeature = createAction(`${ENTITY}_${SPECIALFEATURE}/FETCH`);
export const fetchPropertyValuesSpecialFeatureRequest = createAction(`${ENTITY}_${SPECIALFEATURE}/FETCH_REQUEST`);
export const fetchPropertyValuesSpecialFeatureSuccess = createAction(`${ENTITY}_${SPECIALFEATURE}/FETCH_SUCCESS`);
export const fetchPropertyValuesSpecialFeatureFailure = createAction(`${ENTITY}_${SPECIALFEATURE}/FETCH_FAILURE`);

export const fetchPropertyValuesUtility = createAction(`${ENTITY}_${UTILITY}/FETCH`);
export const fetchPropertyValuesUtilityRequest = createAction(`${ENTITY}_${UTILITY}/FETCH_REQUEST`);
export const fetchPropertyValuesUtilitySuccess = createAction(`${ENTITY}_${UTILITY}/FETCH_SUCCESS`);
export const fetchPropertyValuesUtilityFailure = createAction(`${ENTITY}_${UTILITY}/FETCH_FAILURE`);

export const fetchPropertyValuesEquipment = createAction(`${ENTITY}_${EQUIPMENT}/FETCH`);
export const fetchPropertyValuesEquipmentRequest = createAction(`${ENTITY}_${EQUIPMENT}/FETCH_REQUEST`);
export const fetchPropertyValuesEquipmentSuccess = createAction(`${ENTITY}_${EQUIPMENT}/FETCH_SUCCESS`);
export const fetchPropertyValuesEquipmentFailure = createAction(`${ENTITY}_${EQUIPMENT}/FETCH_FAILURE`);

export const fetchPropertyValuesSpace = createAction(`${ENTITY}_${SPACE}/FETCH`);
export const fetchPropertyValuesSpaceRequest = createAction(`${ENTITY}_${SPACE}/FETCH_REQUEST`);
export const fetchPropertyValuesSpaceSuccess = createAction(`${ENTITY}_${SPACE}/FETCH_SUCCESS`);
export const fetchPropertyValuesSpaceFailure = createAction(`${ENTITY}_${SPACE}/FETCH_FAILURE`);

export const fetchPropertyValuesEnergyEfficiency = createAction(`${ENTITY}_${ENERGYEFFICIENCY}/FETCH`);
export const fetchPropertyValuesEnergyEfficiencyRequest = createAction(`${ENTITY}_${ENERGYEFFICIENCY}/FETCH_REQUEST`);
export const fetchPropertyValuesEnergyEfficiencySuccess = createAction(`${ENTITY}_${ENERGYEFFICIENCY}/FETCH_SUCCESS`);
export const fetchPropertyValuesEnergyEfficiencyFailure = createAction(`${ENTITY}_${ENERGYEFFICIENCY}/FETCH_FAILURE`);

export const fetchPropertyValuesAccess = createAction(`${ENTITY}_${ACCESS}/FETCH`);
export const fetchPropertyValuesAccessRequest = createAction(`${ENTITY}_${ACCESS}/FETCH_REQUEST`);
export const fetchPropertyValuesAccessSuccess = createAction(`${ENTITY}_${ACCESS}/FETCH_SUCCESS`);
export const fetchPropertyValuesAccessFailure = createAction(`${ENTITY}_${ACCESS}/FETCH_FAILURE`);

export const fetchPropertyValuesPermit = createAction(`${ENTITY}_${PERMIT}/FETCH`);
export const fetchPropertyValuesPermitRequest = createAction(`${ENTITY}_${PERMIT}/FETCH_REQUEST`);
export const fetchPropertyValuesPermitSuccess = createAction(`${ENTITY}_${PERMIT}/FETCH_SUCCESS`);
export const fetchPropertyValuesPermitFailure = createAction(`${ENTITY}_${PERMIT}/FETCH_FAILURE`);

export const fetchPropertyValuesAgencyCommission = createAction(`${ENTITY}_${AGENCYCOMMISSION}/FETCH`);
export const fetchPropertyValuesAgencyCommissionRequest = createAction(`${ENTITY}_${AGENCYCOMMISSION}/FETCH_REQUEST`);
export const fetchPropertyValuesAgencyCommissionSuccess = createAction(`${ENTITY}_${AGENCYCOMMISSION}/FETCH_SUCCESS`);
export const fetchPropertyValuesAgencyCommissionFailure = createAction(`${ENTITY}_${AGENCYCOMMISSION}/FETCH_FAILURE`);

export const fetchPropertyValuesStatus = createAction(`${ENTITY}_${STATUS}/FETCH`);
export const fetchPropertyValuesStatusRequest = createAction(`${ENTITY}_${STATUS}/FETCH_REQUEST`);
export const fetchPropertyValuesStatusSuccess = createAction(`${ENTITY}_${STATUS}/FETCH_SUCCESS`);
export const fetchPropertyValuesStatusFailure = createAction(`${ENTITY}_${STATUS}/FETCH_FAILURE`);

export const fetchPropertyValuesPropertyTransactionRiskDegree = createAction(`${ENTITY}_${PROPERTYTRANSACTIONRISKDEGREE}/FETCH`);
export const fetchPropertyValuesPropertyTransactionRiskDegreeRequest = createAction(`${ENTITY}_${PROPERTYTRANSACTIONRISKDEGREE}/FETCH_REQUEST`);
export const fetchPropertyValuesPropertyTransactionRiskDegreeSuccess = createAction(`${ENTITY}_${PROPERTYTRANSACTIONRISKDEGREE}/FETCH_SUCCESS`);
export const fetchPropertyValuesPropertyTransactionRiskDegreeFailure = createAction(`${ENTITY}_${PROPERTYTRANSACTIONRISKDEGREE}/FETCH_FAILURE`);

export const fetchPropertyValuesMainRegister = createAction(`${ENTITY}_${MAINREGISTER}/FETCH`);
export const fetchPropertyValuesMainRegisterRequest = createAction(`${ENTITY}_${MAINREGISTER}/FETCH_REQUEST`);
export const fetchPropertyValuesMainRegisterSuccess = createAction(`${ENTITY}_${MAINREGISTER}/FETCH_SUCCESS`);
export const fetchPropertyValuesMainRegisterFailure = createAction(`${ENTITY}_${MAINREGISTER}/FETCH_FAILURE`);

export const fetchPropertyValuesDescriptions = createAction(`${ENTITY}_${DESCRIPTIONS}/FETCH`);
export const fetchPropertyValuesDescriptionsRequest = createAction(`${ENTITY}_${DESCRIPTIONS}/FETCH_REQUEST`);
export const fetchPropertyValuesDescriptionsSuccess = createAction(`${ENTITY}_${DESCRIPTIONS}/FETCH_SUCCESS`);
export const fetchPropertyValuesDescriptionsFailure = createAction(`${ENTITY}_${DESCRIPTIONS}/FETCH_FAILURE`);

export const fetchPropertyValuesMainRegisterDepartment = createAction(`${ENTITY}_${MAINREGISTERDEPARTMENT}/FETCH`);
export const fetchPropertyValuesMainRegisterDepartmentRequest = createAction(`${ENTITY}_${MAINREGISTERDEPARTMENT}/FETCH_REQUEST`);
export const fetchPropertyValuesMainRegisterDepartmentSuccess = createAction(`${ENTITY}_${MAINREGISTERDEPARTMENT}/FETCH_SUCCESS`);
export const fetchPropertyValuesMainRegisterDepartmentFailure = createAction(`${ENTITY}_${MAINREGISTERDEPARTMENT}/FETCH_FAILURE`);

export const fetchPropertyValuesPuk = createAction(`${ENTITY}_${PUK}/FETCH`);
export const fetchPropertyValuesPukRequest = createAction(`${ENTITY}_${PUK}/FETCH_REQUEST`);
export const fetchPropertyValuesPukSuccess = createAction(`${ENTITY}_${PUK}/FETCH_SUCCESS`);
export const fetchPropertyValuesPukFailure = createAction(`${ENTITY}_${PUK}/FETCH_FAILURE`);

export const fetchPropertyValuesPukDepartment = createAction(`${ENTITY}_${PUKDEPARTMENT}/FETCH`);
export const fetchPropertyValuesPukDepartmentRequest = createAction(`${ENTITY}_${PUKDEPARTMENT}/FETCH_REQUEST`);
export const fetchPropertyValuesPukDepartmentSuccess = createAction(`${ENTITY}_${PUKDEPARTMENT}/FETCH_SUCCESS`);
export const fetchPropertyValuesPukDepartmentFailure = createAction(`${ENTITY}_${PUKDEPARTMENT}/FETCH_FAILURE`);

export const fetchPropertyValuesRegistryDepartmentKpu = createAction(`${ENTITY}_${REGISTRYDEPARTMENTKPU}/FETCH`);
export const fetchPropertyValuesRegistryDepartmentKpuRequest = createAction(`${ENTITY}_${REGISTRYDEPARTMENTKPU}/FETCH_REQUEST`);
export const fetchPropertyValuesRegistryDepartmentKpuSuccess = createAction(`${ENTITY}_${REGISTRYDEPARTMENTKPU}/FETCH_SUCCESS`);
export const fetchPropertyValuesRegistryDepartmentKpuFailure = createAction(`${ENTITY}_${REGISTRYDEPARTMENTKPU}/FETCH_FAILURE`);

export const fetchPropertyValuesRegistryDepartmentKpuSubinsert = createAction(`${ENTITY}_${REGISTRYDEPARTMENTKPUSUBINSERT}/FETCH`);
export const fetchPropertyValuesRegistryDepartmentKpuSubinsertRequest = createAction(`${ENTITY}_${REGISTRYDEPARTMENTKPUSUBINSERT}/FETCH_REQUEST`);
export const fetchPropertyValuesRegistryDepartmentKpuSubinsertSuccess = createAction(`${ENTITY}_${REGISTRYDEPARTMENTKPUSUBINSERT}/FETCH_SUCCESS`);
export const fetchPropertyValuesRegistryDepartmentKpuSubinsertFailure = createAction(`${ENTITY}_${REGISTRYDEPARTMENTKPUSUBINSERT}/FETCH_FAILURE`);

export const fetchPropertyValuesDataSource = createAction(`${ENTITY}_${DATASOURCE}/FETCH`);
export const fetchPropertyValuesDataSourceRequest = createAction(`${ENTITY}_${DATASOURCE}/FETCH_REQUEST`);
export const fetchPropertyValuesDataSourceSuccess = createAction(`${ENTITY}_${DATASOURCE}/FETCH_SUCCESS`);
export const fetchPropertyValuesDataSourceFailure = createAction(`${ENTITY}_${DATASOURCE}/FETCH_FAILURE`);

export const fetchPropertyValuesLandRegistryStatus = createAction(`${ENTITY}_${PROPERTYLANDREGISTRYSTATUS}/FETCH`);
export const fetchPropertyValuesLandRegistryStatusRequest = createAction(`${ENTITY}_${PROPERTYLANDREGISTRYSTATUS}/FETCH_REQUEST`);
export const fetchPropertyValuesLandRegistryStatusSuccess = createAction(`${ENTITY}_${PROPERTYLANDREGISTRYSTATUS}/FETCH_SUCCESS`);
export const fetchPropertyValuesLandRegistryStatusFailure = createAction(`${ENTITY}_${PROPERTYLANDREGISTRYSTATUS}/FETCH_FAILURE`);

export const fetchPropertyValuesOwnershipAcquisition = createAction(`${ENTITY}_${PROPERTYOWNERSHIPACQUISITION}/FETCH`);
export const fetchPropertyValuesOwnershipAcquisitionRequest = createAction(`${ENTITY}_${PROPERTYOWNERSHIPACQUISITION}/FETCH_REQUEST`);
export const fetchPropertyValuesOwnershipAcquisitionSuccess = createAction(`${ENTITY}_${PROPERTYOWNERSHIPACQUISITION}/FETCH_SUCCESS`);
export const fetchPropertyValuesOwnershipAcquisitionFailure = createAction(`${ENTITY}_${PROPERTYOWNERSHIPACQUISITION}/FETCH_FAILURE`);

export const fetchPropertyValuesOwnershipAcquisitionProof = createAction(`${ENTITY}_${PROPERTYOWNERSHIPACQUISITIONPROOF}/FETCH`);
export const fetchPropertyValuesOwnershipAcquisitionProofRequest = createAction(`${ENTITY}_${PROPERTYOWNERSHIPACQUISITIONPROOF}/FETCH_REQUEST`);
export const fetchPropertyValuesOwnershipAcquisitionProofSuccess = createAction(`${ENTITY}_${PROPERTYOWNERSHIPACQUISITIONPROOF}/FETCH_SUCCESS`);
export const fetchPropertyValuesOwnershipAcquisitionProofFailure = createAction(`${ENTITY}_${PROPERTYOWNERSHIPACQUISITIONPROOF}/FETCH_FAILURE`);

export const fetchPropertyValuesPrimiaryUsage = createAction(`${ENTITY}_${PRIMIARYUSAGE}/FETCH`);
export const fetchPropertyValuesPrimiaryUsageRequest = createAction(`${ENTITY}_${PRIMIARYUSAGE}/FETCH_REQUEST`);
export const fetchPropertyValuesPrimiaryUsageSuccess = createAction(`${ENTITY}_${PRIMIARYUSAGE}/FETCH_SUCCESS`);
export const fetchPropertyValuesPrimiaryUsageFailure = createAction(`${ENTITY}_${PRIMIARYUSAGE}/FETCH_FAILURE`);

export const fetchPropertyValuesLandUsage = createAction(`${ENTITY}_${LANDUSAGE}/FETCH`);
export const fetchPropertyValuesLandUsageRequest = createAction(`${ENTITY}_${LANDUSAGE}/FETCH_REQUEST`);
export const fetchPropertyValuesLandUsageSuccess = createAction(`${ENTITY}_${LANDUSAGE}/FETCH_SUCCESS`);
export const fetchPropertyValuesLandUsageFailure = createAction(`${ENTITY}_${LANDUSAGE}/FETCH_FAILURE`);

export const fetchPropertyValuesSupplySource = createAction(`${ENTITY}_${SUPPLYSOURCE}/FETCH`);
export const fetchPropertyValuesSupplySourceRequest = createAction(`${ENTITY}_${SUPPLYSOURCE}/FETCH_REQUEST`);
export const fetchPropertyValuesSupplySourceSuccess = createAction(`${ENTITY}_${SUPPLYSOURCE}/FETCH_SUCCESS`);
export const fetchPropertyValuesSupplySourceFailure = createAction(`${ENTITY}_${SUPPLYSOURCE}/FETCH_FAILURE`);
